.popup-download-app {
   position: fixed;
   z-index: 2;
   top: 15px;
   right: 15px;
   padding: 20px 20px 0 20px;

   width: 240px;
   height: 202px;
   background-color: #FFF;
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

   .close-icon {
      display: none;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 0;
      right: 0;
      padding: 9px;

      cursor: pointer;

      &:active {
         outline: 1px solid #059483;
      }

      @include rwd(1295) {
         display: flex;
      }
   }

   .text {
      color: #505051;
      font-size: 14px;
      margin: 7px 0;
   }

   .download-buttons {
      display: flex;

      .store-buttons {
         margin-left: 10px;

         svg:first-child {
            margin-bottom: 5px;
         }
      }
   }

   @include sm {
      width: 100%;
      height: 172px;
      position: absolute;
      top: unset;
      bottom: 0;
      right: 0;
      padding: 20px 0 0 0;
      text-align: center;

      .text {
         margin-top: 10px;
      }

      .download-buttons {
         display: block;

         .store-buttons {
            margin-top: 20px;
            margin-left: 0;
         }
      } 
   }
}
