.create-message {
  height: 100vh;
  .container{
    flex-direction: column;
    justify-content: space-between;
  }
  .title-section {
    width: 100%;

    .btn-close {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dialog-content {
    padding: 70px 20px 0;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;

    .input {
      resize: none;
      outline: none;
      min-height: 15px;
      border: none;
      border-bottom: 1px solid $light-gray;
      font-size: 16px;
      line-height: 22px;
      color: $light-black;
      padding-bottom: 45px;
      overflow: visible;
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      max-height: calc(100% - 20px);

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $dark-gray;
      }
    }

    .added-file {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .clip {
        margin-right: 6px;
        height: 14px;
        width: 7px;
      }

      .btn-close {
        border: 1px solid $dark-green;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        svg{
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .fixed-btn {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    box-sizing: border-box;
    .add-file {
      height: 0;
      width: 0;
      visibility: hidden;
      padding: 0;
    }

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white;
      color: $dark-green;
      border: 1px solid $dark-green;
      margin: 0 8px 0 0;

      .clip {
        margin-right: 8px;
        width: 7px;
      }
    }
  }
}
