.btn {
  cursor: pointer;
  font-family: $default-font;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  max-width: 400px;
  text-align: center;
  transition: .3s;

  &:disabled {
    background-color: #6c757d;
    opacity: 0.65;
    cursor: not-allowed;
  }

  &-lighter {
    border: 2px solid $green;
    background: $green;
    padding: 10px 0;
    min-width: 160px;
    &:hover{
      border: 2px solid $dark-green-secondary;
    }
    &:active{
      background: $dark-green-secondary;
    }
    &:disabled, &.disable {
      cursor: auto;
      background: $light-gray-secondary;
      color: #d9dee3;
      border: 2px solid $light-gray-secondary;
    }


  }

  &-darker {
    background: $dark-green;
    padding: 14px 0;
  }

  &-user-change {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    width: 45px;
    height: 45px;
    outline: none;
    border: none;
    border-radius: 50%;
    background-color: $lighter-gray;
    cursor: pointer;
    input{
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 0;
      height: 0;
    }
    .pencil {
      width: 21px;
      height: 20px;
      object-fit: contain;
    }
  }

  &-close {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
  }

  &-file-saver {
    margin-top: 15px;
    background: rgba($dark-green, 0.05);
    border-radius: 3px;
    padding: 7px;
    outline: none;
    border: none;
    display: flex;
    --max-lines: 1;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    text-overflow: ellipsis;

    .clip {
      margin-right: 5px;
      width: 7px;
    }
    .file{
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      color: #505051;
    }
  }

  &-call {
    width: 73px;
    height: 73px;
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 15px rgba($black, 0.09);
    margin: 0 15px;

    &-wrap {
      display: flex;
      margin: auto 0 0;
      padding-top: 15px;
      padding-bottom: 30px;
      width: 100%;
      justify-content: center;
      background-color: $lighter-gray;
    }

    &-end {
      background-color: $red;
    }

    &-camera {
      background-color: $white;
    }
  }
}
