.wrapper {
    max-width: 700px;
    background: $white;
    width: 100%;
    height: 100vh;
    margin-right: auto; 
    margin-left: auto;
    margin-bottom: 0px;
}

.main {

    &-container {
        height: 100%;
        width: 100%;
    }

	&-body {
		width: 100%;
		min-height: 100%;
		height: 100%;
		margin: 0px;
		background-color: $white;

		&-head {
			&-wrapper {
				height: 118px;
				width: 100%;
				max-width: 700px;
				margin: 0px;
				padding: 0px;
				position: fixed;
                top: 40px;
                z-index: 9;
				background-color: $white;
			}
		}

		&__search {
			&-bar {
				width: 100%;
				margin: 0px;
			}
		}

		&__header {
			font-family: Nunito Sans;
			margin: 15px;
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 28px;
			color: $text;
		}

		&__search {
			&-input {
				height: 40px;
				margin-left: 15px;
				margin-right: 15px;
				margin-top: 0px;
			}
		}

		&__input {
			&-box {
				background: $white;
				background-image: url("../../img/icons/images/search.svg");
				background-repeat: no-repeat;
				background-position: 15px;
				background-size: 15px;
				padding-left: 40px;
				padding-right: 35px;
				width: 100%;
				height: 40px;
				border: 1px solid $light-gray;
				box-sizing: border-box;
				border-radius: 24px;
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				color: $text;

				&__close {
					&-container {
						display: none;
						width: 9px;
						height: 9px;
						margin-right: 16px;
						margin-left: auto;
						margin-top: -26px;

						&-right {
							height: 12px;
							width: 1px;
							background-color: $dark-green;
							transform: rotate(45deg);
						}

						&-left {
							margin-top: -12px;
							height: 12px;
							width: 1px;
							background-color: $dark-green;
							transform: rotate(135deg);
						}
					}
				}
			}
			&-box:focus {
				@extend .main-body__input-box;
				background-image: url("../../img/icons/images/greensearch.svg");
				background-repeat: no-repeat;
				background-position: 15px;
				background-size: 15px; 
				border: 1px solid $black;         
				color: $text;
				height: 40px;
                outline:none;
              
			}


			&-box:hover {
				border: 1px solid $light-black;
			}
		}

		&__list {
			&-wrapper {
				// height: 100%;
				margin-top: 149px;
				margin-right: 20px;
				margin-left: 20px;
			}

			&-item {
				&-wrapper {
					display: flex;
					width: 100%;
					min-height: 60px;
					height: 100%;
					padding-top: 20px;
					padding-bottom: 20px;
					border-bottom: 1px solid $light-gray;
					cursor: pointer;
				}

				&-nBtn {
					margin-right: 0px;
					border-radius: 50%;
					margin-top: auto;
					margin-bottom: auto;
					min-width: 30px;
					width: 30px;
					height: 30px;
					margin-left: auto;
					background-color: $dark-green;
				}

				&-info {
					margin-top: auto;
					margin-bottom: auto;
					width: fit-content;
				}

				&-title {
					font-family: Nunito Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 25px;
				}

				&-description {
					font-family: Nunito Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 19px;
					color: $text;
				}
			}
		}
	}
}



::placeholder {
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: $text; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: $text;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: $text;
}

.arrow {
	&-next {
		&-top {
			margin-top: 6px;
			margin-left: 16px;
			width: 2px;
			height: 11px;
			background-color: $white;
			transform: rotate(135deg);
		}

		&-bottom {
			@extend .arrow-next-top;
			margin-top: -4px;
			transform: rotate(45deg);
		}
	}
}

.main-body__list-item-wrapper:hover > .link-item_wrapper > .main-body__list-item-nBtn {
	background-color: $dark-green-secondary !important;
}

.link-item_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0px;
    padding: 0px;
    color: $text
}

