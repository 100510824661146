.search {
  position: relative;

  &.active {
    .input {
      border-color: $dark-green;
    }

    .label {
      .search {
        &-text {
          display: none;
        }

        &-icon {
          fill: $dark-green;
        }
      }
    }
  }

  .input {
    width: 92%;
    width: calc(100% - 30px);
    padding: 7px 0 8px 30px;
    border: 1px solid $light-gray;
    border-radius: 24px;
    outline: none;
  }

  .label {
    position: absolute;
    display: flex;
    top: 50%;
    left: 11px;
    width: 94%;
    width: calc(100% - 21px);
    transform: translateY(-50%);

    &.visible {
      .btn-close {
        display: flex;
      }
    }

    .search {
      &-icon {
        margin: auto 6px auto 0;
        width: 13px;
        height: 13px;
        fill: $darker-gray;
      }

      &-text {
        font-size: 14px;
        line-height: 19px;
        color: $dark-gray;
        text-transform: initial;
      }
    }

    .btn-close {
      margin-left: auto;
      display: none;
      .close-icon{
        width: 10px;
      }
    }
  }
}
