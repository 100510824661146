.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &.error {
      .label {
        color: $red-error;
      }

      .input {
        color: $red-error;
      }

      .error-message {
        display: flex;
      }
    }

    .label {
      margin-bottom: 10px;
      span{
        color:$red-error;
      }
    }

    .input {
      font-family: $default-font;
      font-weight: 600;
      font-size: 26px;
      line-height: 35px;
      color: $light-black;
      border: none;
      border-bottom: 1px solid $light-gray;
      outline: none;
      max-width: 197px;
      width: 100%;

      &::placeholder {
        color: rgba($light-black, 0.3);
      }
    }

    .error-message {
      display: none;
      color: $red-error;
      font-family: $default-font;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin-top: 10px;
    }
  }

  .btn {
    margin-top: 30px;
  }

  &-create_account,
  &-edit_account {
    height: 100%;

    .form-group {
      align-items: flex-start;
      max-width: 308px;
      width: 100%;

      &.required-star {
        .label {
          &::after {
            content: "*";
            color: $red-error;
          }
        }
      }
    }

    .label {
      font-size: 14px;
      line-height: 19px;
      color: $dark-green;
    }

    .input {
      max-width: none;
      width: 100%;
      font-size: 16px;
      line-height: 22px;

      &::placeholder {
        color: $dark-gray;
      }
    }

    .btn {
      width: 95%;
      width: calc(100% - 16px);
      margin-top: 0;
    }
    .fixed-btn{
      border-top: 1px solid #F3F3F3;
      margin-top: auto;
    }
  }
  &-create_account{
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-group{
      margin-left: auto;
      margin-right: auto;
    }
  }
}
