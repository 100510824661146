.settings {
  &-links {
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $white;
  }

  &-item {
    border-top: 1px solid $light-gray;
    &:last-of-type {
      border-bottom: 1px solid $light-gray;

      .settings-link {
        color: $red;
        cursor: pointer;
      }
    }
  }

  &-link {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    color: $light-black;
    padding: 15px 30px;
    display: block;
  }
}
