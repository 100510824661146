.videocall-info {
  height: 100vh;
  max-height: calc(var(--vh, 1vh)*100);
  .container {
    flex-direction: column;
    align-items: center;
    background-color: $lighter-gray;
    height: 100%;
  }

  .chat-empty {
    margin-top: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: $light-black;
    padding: 0 15px;

    &-small {
      font-size: 14px;
      line-height: 19px;
      margin-top: 10px;
    }
  }

  .title-section {
    width: 100%;
    position: static;
    transform: translate(0);
    left: initial;
    .btn-close {
      display: flex;
      margin-left: 20px;
    }
  }
}
