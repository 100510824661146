.sv-question {
	.noUi {
		&-target {
			margin-top: 100px;
		}

		&-ltr {
		}

		&-horizontal {
			height: 3px;
			margin-left: 15px;
			margin-right: 15px;
			width: auto;
			border: none;
		}

		&-horizontal::before {
			content: attr(data-slider);
			position: absolute;
			left: calc(100% / 2 - 64px);
			margin-top: -88px;
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: 600;
			font-size: 64px;
			line-height: 87px;
			text-align: center;
		}

		&-base {
			width: 100%;
			height: 5px;
		}

		&-pips {
			&-horizontal {
			}
		}

		&-connect {
			background-color: transparent;
		}

		&-handle {
			position: relative;
			border-radius: 50%;
			border: solid 3px $dark-green;
			position: unset;
			width: 30px !important;
			height: 30px !important;
			margin-top: -14px;
			margin-left: -15px !important;
			margin-left: 0px;
			cursor: pointer;

			&-lower {
			}
		}

		&-handle::after {
			display: none;
		}

		&-handle::before {
			display: none;
		}

		&-handle:focus {
			outline: none;
		}

		&-origin {
			// position: unset;
			// z-index: unset;
			// top:unset;
			// right:unset;
		}

		&-tooltip {
			display: none;
			transform: unset;
			left: -25px;
			bottom: 2px;
			border: none;
			font-weight: Nunito Sans;
			font-weight: 600;
			font-size: 32px;
			line-height: 47px;
			text-align: center;
			background-color: transparent;
			position: fixed;
		}

		&-marker {
			display: none;

			&-horizontal {
				position: relative;
			}

			&-large {
			}

			&-normal {
			}
		}

		&-value {
			display: none;
		}
	}
}

.btn-noUi-plus {
	right: 35%;
	top: 40px;
	padding-top: 5px;
	position: absolute;
	text-align: center;
	color: $dark-green;
	width: 40px !important;
	height: 40px !important;
	border: 1px solid $dark-green;
	font-size: 45px;
	cursor: pointer;
}

.btn-noUi-plus:hover {
	@extend .btn-noUi-plus;
	background-color: $ultra-light-green;
}

.btn-noUi-plus:active {
	@extend .btn-noUi-plus;
	background-color: $dark-green;
	color: $white;
}

.btn-noUi-plus:after {
	content: "+";
}

.btn-noUi-minus {
	@extend .btn-noUi-plus;
	left: 35%;
}

.btn-noUi-minus:hover {
	left: 35%;
	background-color: $ultra-light-green;
}

.btn-noUi-minus:active {
	left: 35%;
	background-color: $dark-green;
	color: $white;
}

.btn-noUi-minus:after{
	content: "-"
}

.btn-noUI-invisible {
	@extend .btn-noUi-plus;
	left: 35%;
	border: 1px solid $light-gray;
	color: $light-gray;
}

.btn-noUI-invisible:hover {
	background-color: transparent;
}

.noUi-connect {
	box-shadow: unset !important;
}