@import "vars";
@import "fonts";
@import "libs";
@import "mixins/media";

body {
  font-family: $default-font;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  --lh: 20px;
  line-height: var(--lh);
}

.container {
  display: flex;
  margin: 0 auto;
  max-width: 700px;
  box-shadow: 0px 4px 35px rgba($darker-gray, 0.25);
  background-color: $white;
  height: 100%;
}

section {
  position: relative;

  &::after {
    position: fixed;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/total-bg.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    z-index: -10;
  }
}

.overflow-hidden {
  overflow: hidden;
}

strong,
b {
  font-weight: bolder;
}

a {
  text-decoration: none;
}

button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.active-slider {
  display: flex !important;
  animation: fade;
  animation-timing-function: ease;
  animation-duration: 0.6s;
}

.loading-page {
  position: relative;
  z-index: 1;
  transition: all 300ms linear;

  &::before {
    position: fixed;
    opacity: 1;
    height: 100%;
    height: calc(100% - 55px);
    z-index: 999999999;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 14px;
    background-color: $white;
  }

  &::after {
    position: fixed;
    z-index: 9999999999;
    content: "";
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: transparent url("../img/loader-icon.svg") center center no-repeat;
    background-size: contain;
    animation: RotatePageLoader 4s linear infinite;
  }
}

.chat-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px auto 0;
  max-width: 250px;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: $light-black;
  display: none !important;

  &-small {
    font-size: 14px;
    line-height: 19px;
    max-width: 300px;
    width: 100%;
    margin-top: 10px;
  }
}

.fixed-btn {
  padding: 8px 0;
  background-color: $white;
  width: 100%;
  display: flex;
  justify-content: center;

  .btn {
    width: 100%;
  }
}

// Animations
@keyframes RotatePageLoader {
  0% {
    transform: translateX(-50%) rotate(0);
  }

  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

// UI elements
@import "UI/button";
@import "UI/form";
@import "UI/title";
@import "UI/text";
@import "UI/search";
@import "UI/tab";
@import "UI/user-info";
@import "UI/user-settings";
@import "UI/notification";
@import "UI/event-feature";
@import "UI/user-frame";
@import "UI/doctor-frame";
@import "UI/message-feature";
@import "UI/modals";
@import "UI/loader";
@import "UI/naviagtion";
@import "UI/list";

// Pages elements
@import "pages/login";
@import "pages/create-account";
@import "pages/tabs";
@import "pages/edit-account";
@import "pages/videocall-info";
@import "pages/videocall";
@import "pages/dialog";
@import "pages/create-message";

// Components
@import "components/popup-download-app";

// Survey 
@import "quiz/general";
@import "quiz/imagemediaquery";
@import "quiz/moderntheme";
@import "quiz/nouislider";
@import "quiz/select2";
