.user {
  &-frame {
    width: 110px;
    height: 160px;
    object-fit: cover;
    box-shadow: 0 4px 15px rgba($black, 0.23);
    margin: 0 15px;

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin: auto 0 0;
      background-color: $lighter-gray;
      padding-bottom: 15px;
      z-index: 2;

      padding-right: 10px;
      box-sizing: border-box;
    }
  }
}
