.event {
  &-feature {
    display: flex;
    padding: 20px 15px;
    border-bottom: 1px solid $light-gray;

    .photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;

      &-wrap {
        position: relative;
        margin-right: 15px;

        .camera {
          &-wrap {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $lighter-gray;
            svg{
              width: 11px;
            }
          }
        }
      }
    }
  }

  &-date {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .date {
      font-size: 12px;
      line-height: 16px;
      color: $darker-gray;
    }
  }

  &-new {
    margin-top: 7px;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    text-transform: uppercase;
  }

  &-info.event-info-tab {
    max-width: 55%;
    padding: 0;
    align-items: flex-start;

    .text {
      &-bold {
        font-size: 16px;
        line-height: 22px;
        color: $dark-green;
      }
    }

    .status {
      &-icon {
        margin-right: 6px;
        width: 12px;
        height: 12px;
        margin-bottom: 1px;
        path{
          //fill: none;
        }
      }

      &-text {
        font-size: 13px;
        line-height: 18px;

        &-loading {
          color: $rose;
        }

        &-soon {
          color: $yellow;
        }

        &-future {
          color: $violet;
        }

        &-completed,
        &-canceled {
          color: $darker-gray;
        }
      }
    }
  }

  &-subinfo {
    display: flex;
    align-items: center;
  }
}
