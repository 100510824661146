.user {
  &-info {
    margin: 100px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-photo {
    position: relative;
    width: 140px;
    height: 140px;
    margin-bottom: 20px;

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 2px solid #E8E8E8;
      box-sizing: border-box;
    }
  }

  &-name {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: $light-black;
    margin-bottom: 5px;
  }

  &-code {
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $darker-gray;
  }
}
