.title {
  font-family: 'Nunito Sans';
  font-style: normal;

  &-primary {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: $darker-gray;
  }

  &-section {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: $light-black;
    padding: 18px 0;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    width: calc(100% - 30px);
    max-width: 700px;
    background-color: $white;
    z-index: 2;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $light-gray;
    }
    .arrow-back{
      width: 19px;
      height: 19px;
    }
  }
}
