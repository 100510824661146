.select2 {
	&-container {
		margin-top: 10px;

	}

	&-selection {
		&__placeholder {
			padding-left: 15px !important;
			padding-right: 15px !important;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: $dark-gray !important;
		}

		&__arrow {
			border: none !important;
			background-image: none !important;
			background-color: $white !important;
		}

		&__clear {
			font-size: 20px;
			color: $dark-green;
			position: relative;
			right: 10px;
			z-index: 20;
		}

		&__rendered {
			margin-top: 11px;
			font-style: normal;
			font-weight: bold;
			color: $black;
		}

		&--single {
			height: 50px !important;
		}
	}

	&-results {
		&__option {
			font-family: Nunito Sans;
			font-style: normal;
			padding-right: 20px;
			padding-left: 20px;
			margin-bottom: 0px;
			color: $black !important;
			font-family: Nunito Sans;
			font-style: normal;
			font-size: 18px;
			font-weight: 600;
			// border-bottom: 1px solid $drop-down-border-color;;

			&--highlighted {
				background-color: $lighter-gray !important;
				color: $black !important;
				font-family: Nunito Sans;
				font-style: normal;
				font-size: 18px;
			}
		}
	}

	&-search {
		border-bottom: 1px solid $light-gray;
		border-radius: 5px;

		&__field {
			border-radius: 0px;
			padding-left: 15px !important;
			padding-right: 15px !important;
			border: none !important;
			font-family: Nunito Sans;
			font-size: 18px;
			font-style: normal;
			font-weight: bold;
			color: $black;
		}
	}
}

.select2-container--classic .select2-selection--multiple {
	background: $white;
	border: 1px solid $light-gray;
	// box-sizing: border-box;
	border-radius: 24px;
	height: 40px;
	padding-top: 8px;
	padding-bottom: 7px;
}

.select2-container--classic
	.select2-selection--multiple
	.select2-selection__rendered {
	position: relative;
	top: 50px;
}

.select2-container--classic .select2-selection--single {
	background-image: none !important;
	background-color: $white !important;
	height: 50px;
}

.select2-container--classic .select2-selection--single:hover {
	border: 1px solid $black;
}

.select2-container--classic .select2-selection--single:focus {
	border: 1px solid $black !important;
}

.select2-container--classic.select2-container--open .select2-selection--single {
	border: 1px solid $black !important;
	// border-bottom: none !important;
}

.select2-container--classic.select2-container--open .select2-dropdown {
	border: 1px solid $black !important;
	// border-top: none !important;
}

b {
	border-color: $dark-green transparent transparent transparent !important;
	border-style: none;
	border-width: 0px !important;
	height: 0px;
	position: absolute;
	width: 0px;
	// display: none;
}

b:after {
	position: relative;
	right: 10px;
	border: solid $dark-green;
	width: 4px;
	height: 4px;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	content: " ";
	transform: rotate(45deg);
}


.select2-selection .select2-selection--single .select2-selection__clear {
	margin-left: 55px;
}

// scrollbars

/*width*/
::-webkit-scrollbar {
	width: 4px;
	
}

/* Track*/
::-webkit-scrollbar-track {
	background-color: transparent;
}

/*Handle */

::-webkit-scrollbar-thumb {
	background-color: $black;
	border-radius: 4px;
	position: relative;
}

/*Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background-color: $dark-gray;
}
