.modal {
  position: relative;
  padding: 25px;
  background-color: $white;
  transition: transform 300ms ease-in-out;
  transform: translateY(-50px);
  overflow: auto;
  max-height: 100vh;
  width: 75%;
  max-width: 350px;

  &-wrap {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($dark-gray, 0.5);
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    transform: translateY(-100%);
    transition: opacity 300ms ease-in-out, transform 0s .3s;

    &[data-id="2"] {
      background: rgba($black, 0.2);

      .modal {
        padding: 30px;
        transition: none;

        .loader {
          width: 40px;
          height: 40px;
        }

        .text {
          font-size: 18px;
          line-height: 25px;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
    }

    &[data-id="1"] {
      @include rwd(420) {
        .modal {
          width: 85%;
        }
      }
    }

    &.active{
      transform: translateY(0);
      opacity: 1;
      z-index: 999999;
      transition: opacity 300ms ease-in-out, transform 0s 0s;
    }
  }

  &-active {
    z-index: 999999;
    opacity: 1;

    .modal {
      transform: translate3d(0, 0, 0);
    }
  }

  &-close {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 5px;

    img, svg {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .text {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: $light-black;
      margin-bottom: 30px;
    }

    .title-text {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $light-black;
      margin-bottom: 10px;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;

      .btn {
        min-width: 140px;
        margin: 0 10px;
        padding: 10px 0;

        &-light {
          padding: 9px 0;
          background-color: $white;
          color: $dark-green;
          border: 1px solid $dark-green;
        }
      }
    }
  }
}
