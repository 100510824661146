

.dialog {
  height: 100vh;

  .container {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }

  .title-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    transform: translate(0);
    left: 0;

    padding: 10px 0;

    .btn-close {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .subtext {
      font-size: 12px;
      line-height: 16px;
      color: $darker-gray;
      text-transform: capitalize;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;
    transform: translate(0);
    flex: 1 1 100%;
    max-height: calc(100% - 39px);

    &.loading-page::before {
      bottom: 58px;
    }
    .scroll-view{
      flex: 1 1 100%;
      width: 100%!important;
      height: initial!important;
      & > div {
        padding-left: 6px;
      }
    }
    .scrollbar {
      &::-webkit-scrollbar {
          width: 6px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
          margin: 2px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
      }
    }
  }

  .btn-darker {
    margin-top: 8px;
  }

  &-item {
    display: flex;
    padding: 20px 15px;
    border-top: 1px solid $light-gray;
    max-width: 100%;

    &.income {
      background-color: $white;
    }

    &.outcome {
      background-color: $lighter-gray;
    }

    &.message-error {
      .dialog-time {
        display: none;

        &.error {
          display: block;
        }
      }
    }

    &.message-loader {
      .dialog-time {
        display: none;

        &.loader {
          display: block;
        }
      }
    }

    .photo {
      width: 35px;
      height: 35px;
      object-fit: cover;
      border-radius: 50%;

      &-wrap {
        margin-right: 15px;
      }
    }
  }

  &-message {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-content {
      display: flex;
      flex-direction: column;

      .text {
        margin: 0;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 22px;
        color: $light-black;
      }

      .btn-file-saver {
        margin-right: auto;
        margin-top: 5px;
      }
    }
  }

  &-topper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    .user-name {
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: $darker-gray;
      margin: 0;
    }
  }

  &-time {
    font-size: 12px;
    line-height: 16px;
    color: $darker-gray;

    &.error {
      display: none;
    }

    &.loader {
      display: none;
    }
  }

  &-info {
    padding: 7px 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: $light-black;
    display: flex;
    justify-content: center;

    &-unread {
      background-color: $light-gray;
    }

    &-date {
      background-color: rgba($dark-green, 0.1);
    }
  }

  .fixed-btn {
    max-width: 700px;
  }
}
