.create-acc {
  height: 100vh;

  .container {
    flex-direction: column;
    padding: 0 8px;
    height: 100vh;
  }

  .title-section {
    width: 100%;
    background-color: $white;
  }
}
