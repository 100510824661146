@media only screen and (min-width: 1000px) {
	.sv-container-modern__title {
		margin-left: 15px;
		margin-right: 15px;
	}

	.sv-container-modern__title {
		font-family: Nunito Sans;
		padding-left: 0px !important;
	}

	.sv-body__page,
	.sv-body__footer {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}

	.sv-row__question {
		margin-bottom: 100px;
	}
}
@media only screen and (max-width: 1000px) {
	.sv-container-modern__title {
		padding-left: 0px !important;
		font-family: Nunito Sans;
		margin-left: 15px !important;
		margin-right: 15px !important;
	}

	.sv-container-modern__title {
		font-family: Nunito Sans;
	}

	.sv-row__question {
		margin-bottom: 100px;
	}
	.sv-body__page,
	.sv-body__footer {
		margin-top: 0px;
		margin-right: 15px !important;
		margin-left: 15px !important;
	}
}

@media screen and (min-height: 985px) {
	.main-container {
		height: 100vh;
	}

	.content-wrapper {
		height: 100%;
	}
	.text-items_wrapper {
		height: 100%;
	}
}

@media screen and (min-width: 900px) {
	.main-image {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 50%;
	}

	.container-main_image {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media screen and (max-width: 400px) {
	.btn-noUi-plus {
		right: 25%;
		top: 40px;
		padding-top: 5px;
		position: absolute;
		text-align: center;
		color: $text;
		width: 40px !important;
		height: 40px !important;
		border: 1px solid $darker-gray;
		font-size: 45px;
		cursor: pointer;
	}

	.btn-noUi-plus:after {
		content: "+";
	}

	.btn-noUi-minus {
		left: 25%;
	}

	.btn-noUi-minus:after {
		content: "-";
	}

	.btn-noUI-invisible {
		left: 25%;
		border: 1px solid $darker-gray;
		color: $text;
	}

	.noUi-connect {
		box-shadow: unset !important;
	}
}

@media screen and (max-width: 340px) and (max-height: 530px) {
	.sv {
		&-clearfix {
			margin-bottom: 60px;
		}
	}
}

