div:not([class]) {
	// height: 100%;
	display: flex;
	flex-flow: column;
}

div[class=""] {
	height: 100%;
	display: flex !important;
	flex-flow: column;
}

p {
	height: 100%;
	margin: 0;
	margin-left: 15px;
	margin-right: 15px;
	justify-content: center;
}

img {
	display: block;
	margin: auto;
	justify-self: center;
	// height: 100%;
	width: 100%;
}

input:focus {
	border: none;
	outline: none;
}

.sv-row:not(:last-child) {
	padding-bottom: 0px;
}
