.tabs {
  height: var(--app-height);

  .container {
    flex-direction: column;
  }

  .title-section {
    padding: 12px 15px;
    max-width: 670px;
  }

  .tab-content {
    .chat-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 200px auto 0;
      max-width: 250px;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: $light-black;
      display: none;

      &-small {
        font-size: 14px;
        line-height: 19px;
        max-width: 300px;
        width: 100%;
        margin-top: 10px;
      }
    }

    &-events {
      .title-section {
        padding: 16px 0 0;
        width: 100%;
      }
    }
  }

  .user {
    &-info {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
}
