.login {
  height: var(--app-height);
  height: calc(var(--vh, 1vh) * 100);
  min-height: 100vh;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../img/total-bg.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    z-index: -10;
  }

  .container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    path{
      fill:$light-green;
    }

    &-wrap {
      max-width: 158px;
      max-width: 162px;
      padding: 65px 0 0;
    }
  }

  .form {
    margin-top: 60px;
  }
}
