.doctor-frame {
  object-fit: cover;
  width: 100%;
  height: 100%;

  &-wrap {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;
  }
}
