.load-more-loader{
  position: relative;
  width: 100%;
  height: 60px;
  opacity: 1;
  margin-top: 10px;
  &.changed-size{
    .wrapper-loader{
      .lds-spinner{
        div{
          transform-origin: 50% 50%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          &:after{
            top: 3.75%;
            left: 46.25%;
            width: 9.1%;
            height: 25%;
            border-radius: 20%;
          }
        }
      }
    }
  }
  .wrapper-loader{
    background: transparent;
    .lds-spinner{
      width: 40px;
      height: 40px;
      div{
        transform-origin: 20px 20px;
        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: 1.5px;
          left: 18.5px;
          width: 3px;
          height: 9px;
          border-radius: 20%;
          background: $dark-green;
        }
      }
    }
  }
}

.overflow-by-loader{
  position: relative;
  height: 100%;
}

.wrapper-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(200,200,200,.7);
  transition: opacity .3s;
  opacity: 0;
  pointer-events: none;
  &.show{
    opacity: 1;
    pointer-events: all;
  }
}
.lds-spinner {
  color: $dark-green;
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 3;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: $dark-green;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}