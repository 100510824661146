.message {
  &-feature {
    background-color: $white;

    &.unread {
      background-color: $lighter-gray;
    }

    .event {
      &-info {
        max-width: 60%;
        width: 100%;
      }

      &-date {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &-text {
    font-size: 14px;
    line-height: 19px;
    color: $light-black;
    --max-lines: 2;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-unread {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    background: $red;
    border-radius: 10.5px;
    color: $white;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-top: auto;
  }
}
