.navigation-container {
	position: fixed;
	background-color: $white;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	width: 100%;
    min-height: 39px;
	max-width: 700px;
	padding: 0px;
	margin: 0px;
	border-bottom: 1px solid $light-gray;
	z-index: 900;
}

.grid-item {
	display: flex;
	justify-content: center;
}

.grid-item_start {
	@extend .grid-item;
	margin-left: 0;
	justify-content: flex-start;
}

.arrow-container {
	margin-top: 15px;
	margin-left: 18px;
	margin-bottom: 0px;
	padding: 0;
	height: 24px;
}

.arrow-line {
	background-color: $dark-green;
	width: 12px;
	height: 4px;
}

.arrow-line_top {
	@extend .arrow-line;
	margin-top: 8px;
	transform: rotate(135deg);
}

.arrow-line_bottom {
	@extend .arrow-line;
	margin-top: 2px;
	transform: rotate(45deg);
}

.visit_header-text {
	font-family: Nunito Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
	text-transform: uppercase;
	color: $darker-gray;
}

.back-btn-wrapper {
	cursor: pointer;
	width: fit-content;
	height: 100%;
}
