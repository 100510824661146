.videocall {
  height: 100vh;
  &.light{
    .container {
      & > div{
        background-color: $white;
      }
    }
  }
  .container {
    & > div{
      flex-direction: column;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      background-color: $lighter-gray;
      width: 100%;
    }
  }

  &-topper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17px;
    background-color: $white;
    width: 100%;

    .doctor-name {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      text-transform: uppercase;
      color: $light-black;
      margin-bottom: 5px;
    }

    .time {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $light-black;

      &-remaining {
        color: $green;
      }
    }
  }

  .user-frame-wrap,
  .btn-call-wrap {
    background-color: transparent;
  }
  .btn-call{
    cursor: pointer;
    &.btn-call-camera{
      .btn-call-icon{
        width: 39px;
      }
    }
  }

  .btn-call-wrap {
    margin-top: 0;
    .btn-call-icon{
      width: 49px;
    }
  }
}

.event {
  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 385px;
    margin: 0 auto;
    padding-bottom: 15px;
  }

  &-time {
    width: 100%;
    padding-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .duration,
    .remaining {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
    }

    .duration {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: $light-black;
    }

    .remaining {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $darker-gray;

      &-time {
        color: $light-black;
      }
    }

    .waiting {
      font-size: 14px;
      line-height: 19px;
      color: $green;
    }
  }

  &-info {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: none;

    .user-photo {
      margin-bottom: 20px;
    }

    .doctor {
      &-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        color: $light-black;
      }

      &-specialization {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: $darker-gray;
      }
    }
  }

  &-description {
    padding: 0 15px 0;
    background-color: $lighter-gray;

    .description.text {
      width: 100%;
      display: flex;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: $darker-gray;
      padding: 0 0 10px;
      border-bottom: 1px solid $light-gray;
      margin-bottom: 15px;
    }

    .text {
      padding: 5px 0;
      margin: 0;
      font-size: 14px;
      line-height: 19px;
      color: $light-black;
    }
  }
}
