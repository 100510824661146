.notification {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  border-radius: 2px;
  font-weight: bold;

  &-wrap {
    background-color: transparent;
    position: fixed;
    bottom: 78px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 210px;
    width: max-content;
    transition: bottom 0.5s 0.2s;
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  &-success {
    background: rgba($dark-green, 0.1);
    color: #059483;
  }

  &-error {
    background: rgba($red, 0.1);
    color: #dc3545;
  }

  &-icon {
    margin-right: 10px;

    &-close {
      margin-left: 10px;
    }
  }

  .btn-close {
    display: none;
  }
}
