.tabs{
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.tab-links.tab-links-main{
  & + .tabs{
    height: calc(100vh - 67px);
    height: calc(var(--vh, 1vh) * 100 - 67px);
  }
}

.tab {
  &-links {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;

    &-main {
      max-width: 700px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $light-gray;
    }
    .wrapper-active-line{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.6s ease;
    }
    .active-line {
      width: 47px;
      height: 3px;
      display: inline-block;
      background-color: $dark-green;
      border-radius: 15%;
    }
  }

  &-item {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px 0 7px;
    width: 7%;

    &.active {
      .tab-icon {
        fill: $dark-green;

        &-account {
          fill: none;
          stroke: $dark-green;
        }
      }

      .tab-signature {
        color: $dark-green;
      }
    }

    @include rwd(580) {
      width: 9%;
    }

    @include rwd(470) {
      width: 11%;
    }

    @include rwd(425) {
      width: 13%;
    }

    @include rwd(360) {
      width: 15%;
    }
  }

  &-indicator {
    position: absolute;
    padding: 2px 6px;
    border-radius: 9px;
    background-color: $red;
    font-size: 10px;
    line-height: 14px;
    color: $white;
    top: 7px;
    right: 5px;
  }

  &-signature {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $darker-gray;
    transition: color 0.6s ease;
  }

  &-icon {
    width: 30px;
    height: 26px;
    fill: $darker-gray;
    transition: fill 0.6s ease;

    &-account {
      fill: none;
      stroke: $darker-gray;
      transition: stroke 0.6s ease;
    }
  }

  &-content {
    width: 100%;
    height: 100%;
    display: flex;

    &-item {
      width: 100%;
      display: none;
      padding: 54px 0 0;
      background-image: $white;
      position: relative;
      transform: translateY(0);

      &:first-child {
        display: block;
      }
      &.loading-page {
        &::before {
          position: fixed;
          opacity: 1;
          height: 100%;
          height: calc(100% - 117px);
          z-index: 999999999;
          content: "";
          bottom: 62px;
          left: 0;
          width: 100%;
          border-radius: 14px;
          background-color: $white;
        }
      }
    }
  }
  &-message{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #505051;
    margin: 150px 30px 0px ;
  }
}

.subtab {
  &-links {
    display: flex;
    justify-content: space-around;

    .active-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      height: 2px;
      background-color: $dark-green;
      transition: all 0.6s ease;
    }
  }

  &-item {
    width: 50%;
    cursor: pointer;
    padding: 9px;
  }

  &-signature {
    font-size: 14px;
    line-height: 19px;
    color: $dark-green;
  }

  &-content {
    height: 100%;
    width: 100%;
    display: flex;

    &-item {
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      justify-content: space-between;
      /* height: 100%; */
      flex: 1 1 100%;

      &:first-of-type {
        display: block;
      }

      &.loading-page {
        &::before {
          position: fixed;
          opacity: 1;
          height: 100%;
          height: calc(100% - 117px);
          z-index: 999999999;
          content: "";
          bottom: 62px;
          left: 0;
          width: 100%;
          border-radius: 14px;
          background-color: $white;
        }
      }
    }
  }
}
