.edit-acc {
  height: 100vh;

  .container {
    flex-direction: column;
    height: 100%;
  }

  .title-section {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $white;

    .btn-close {
      position: absolute;
      top: 18px;
      left: 20px;
    }
  }

  .fixed-btn {
    position: relative;
    z-index: 2;
  }
}
