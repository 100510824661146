@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap");

.sv {
	font-family: 'Nunito Sans';
	font-style: normal;

	&-custom_header {
	}

	&-root-modern {
		width: 100%;

		form {
			width: 100%;
			height: 100%;
			background-color: $white;
		}
	}

	&_custom_header {
	}

	&-container-modern {
		height: 100%;
		width: 100%;

		&__title {
			padding: 0px;
			margin-top: 5px;
			margin-left: 15px !important;
			margin-right: 15px !important;
		}
	}

	&-description {
		padding: 0!important;
		span {
		}
	}

	&-header {
		&__text {
			margin-top: 15px;

			h3 {
				margin: 0;
				margin-bottom: 3px !important;
				font-weight: normal;
				font-size: 24px;
				line-height: 28px;
				color: $dark-gray;
				font-family: Nunito Sans;
				font-style: normal;

				h5 {
					margin-top: 5px;
				}
			}

			h4 {
				margin: 0;
				font-weight: 600;
				font-size: 24px;
				line-height: 28px;
				color: $text;
				font-family: Nunito Sans;
				font-style: normal;
				span {
					margin-right: 15px;
					margin-left: 15px;
				}
			}

			h5 {
				margin: 0;
				padding: 0;
				font-weight: 600;
				font-size: 24px;
				line-height: 28px;
				color: $text;
				font-family: Nunito Sans;
				font-style: normal;
				span {
				}
			}
		}
	}

	&-body {
		width: 100%;
		height: fit-content;
		background-color: $white;

		div:not([class]) {
			min-width: 100% !important;
			height: auto;
			margin-left: 15px;
			display: none;
			font-family: Nunito Sans;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
		}

		&__page {

			margin-top: 0px !important;

			h4 {
				margin-left: 0px;
				margin-right: 0px;
				padding: 0px;
			}
		}

		&__footer {
			margin: 0px;
			padding: 0px;
		}

		&__propgress {
		}
	}

	&-panel {
		padding: 0px;

		h4 {
			width: fit-content;
			span {
				font-family: Nunito Sans;
			}
		}

		&__content {
			h5 {
				width: fit-content;
				span {
					font-family: Nunito Sans;
				}
			}
		}

		&__description {
			span {
				padding: 0px;
			}
		}
	}

	&-row {
		height: 100%;
		width: 100%;
		// min-width: auto !important;
		margin-bottom: 100px;

		&__question {
			width: 100% !important;
		}

		&__panel {
			min-width: auto !important;
		}
	}

	&-panel {
		&__contnent {
			display: flex;
			height: 100%;
		}

		&__title {
			font-size: 16px;
		}
	}

	&-question {
		overflow: hidden;

		min-width: auto !important;

		input {
			// background-color: transparent !important;
		}

		input:focus {
			// background-color: transparent !important;
		}

		div {
			margin: 0;
			width: 100%;
		}

		hr {
			display: none;
		}

		&__erbox {
			&--location {
				&--top {
					margin-left: 15px;
				}
			}
		}

		&__header {
			&--location--top {
				// text-align: center;
				margin-top: 10px;
			}
		}

		&__title {
			padding: 0px;
			padding-left: 0px !important;
			font-size: 16px;
			font-style: normal;
			font-weight: normal;
			line-height: 22px;

			h5 {
			}

			span {
				font-family: Nunito Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 25px;
				color: $text;
			}

			&--required {
				font-family: Nunito Sans;
				display: block;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 25px;
				color: $text;
				background-color: transparent !important;
			}

			&--answer {
				background-color: transparent !important;
				span {
					font-family: Nunito Sans;
					font-style: normal;
					font-weight: normal;
					font-size: 24px !important;
					line-height: 25px;
					color: $text;
				}
			}
		}

		&__content {
			width: 100%;
			display: flex !important;

			p {
				margin-bottom: auto;
				display: block;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 22px;
				color: $text;
				font-family: Nunito Sans;
			}

			input {
				background-color: transparent;
			}

			div:not([class]) {
				width: 100%;
				margin-left: 0px !important;
				padding: 0px !important;
				display: flex !important;
				overflow-x: hidden !important;

				p {
					margin: 0;
				}

				span {
					// display: none;
				}
			}
		}
	}

	&-clearfix {
		margin-bottom: 0px;
	}

	&-title {
		font-family: Nunito Sans !important;
		height: fit-content;
		padding: 0px !important;
	}

	&-btn {
		text-align: center !important;
	}

	&-footer {
		position: fixed;
		bottom: 0px;
		width: 100%;
		max-width: 700px;
		display: flex;
		padding: 0px !important;
		margin: 0px !important;
		z-index: 900;
		background-color: $white;

		&__start-btn {
			margin-top: 8px !important;
			margin-bottom: 8px !important;
			margin-right: 8px !important;
			margin-left: auto !important;
			height: 49px;
			border: none;
			border-radius: unset !important;
			width: 50% !important;
			max-width: 388px;
			text-align: center;
			background-color: $green;
			color: $white;
			font-family: Nunito Sans;
			font-weight: bold;
			font-style: normal;
			font-size: 16px;
			line-height: 22px;
			padding: 0px !important;
			display: block;
		}

		&__start-btn:hover {
			background-color: $dark-green-secondary !important;
		}

		&__next-btn {
			@extend .sv-footer__start-btn;

			width: 47%;
			margin-left: 8px !important;
			margin-right: 8px !important;
			border-radius: unset;
			text-align: center;
			background-color: $green;
		}

		&__next-btn:hover {
			background-color: $dark-green-secondary !important;
		}

		&__prev-btn {
			@extend .sv-footer__start-btn;

			width: 47%;
			margin-top: 8px !important;
			margin-right: 0px !important;
			margin-left: 8px !important;
			border-radius: unset !important;
			text-align: center;
			color: $dark-green !important;
			border: 1px solid $dark-green !important;
			background-color: $white !important;
		}

		&__prev-btn:hover {
			background-color: $ultra-light-green !important;
		}

		&__prev-btn:active {
			background-color: $dark-green !important;
			color: $white !important;
		}

		&__complete {
			&-btn {
				width: 47% !important;
				margin-top: 8px !important;
				margin-right: 8px !important;
				margin-left: 8px !important;
				margin-bottom: 8px !important;
				border-radius: unset !important;
				text-align: center;
				background-color: $green;
			}

			&-btn:hover {
				background-color: $dark-green !important;
			}
		}
	}

	&-btn {
		padding: 0px;
		height: 49px;
		border: none;
		border-radius: unset;
		width: 100%;
		background-color: $green;
		color: $white;
		font-family: Nunito Sans;
		font-weight: bold;
		font-style: normal;
		font-size: 16px;
		line-height: 22px;
	}

	&-page {
		font-family: Nunito Sans;
		font-style: normal;

		&__title {
			margin-top: 15px;
			padding: 0px;
			margin-bottom: 15px;
			display: flex;
			font-weight: 600;
			font-size: 24px !important;
			line-height: 28px;
			color: $text;
			font-family: Nunito Sans;
			font-style: normal;

			span {
			}
		}
	}

	&-progress {
		display: none;
		margin-right: 8px;
		margin-left: 8px;
		margin-bottom: 60px;
		width: 95%;
		position: fixed;
		bottom: 20px;
		background-color: transparent !important;

		&__bar {
			span {
			}
		}

		&__text {
			margin: 0px;
			padding: 0px;
		}

		span {
		}
	}

	&-hidden {
		width: 100%;
	}

	&-selectbase {
		border: none;
		width: 100%;
		margin-bottom: 60px !important;

		&__item {
			margin-top: 15px;
		}

		&__label {
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 25px;
			color: $text;
		}
	}

	&-item {
		&__decorator {
			margin-bottom: 15px;
			margin-top: 15px;
			margin-left: 0px;
			background-color: $light-gray-secondary;
			background-image: none;
			border-radius: 50% !important;
			vertical-align: middle !important;
			border: 1px solid $dark-green !important;
			-webkit-appearance: none;
			height: 30px !important;
			width: 30px !important;
			outline: none;
			cursor: pointer;
		}

		&__control {
			margin-bottom: 15px;
			margin-top: 15px;
			margin-left: 0px;
			background-color: $light-gray-secondary;
			background-image: none;
			border-radius: 50%;
			vertical-align: middle;
			border: 1px solid $dark-green;
			-webkit-appearance: none;
			height: 30px;
			width: 30px;
			outline: none;
			cursor: pointer;
		}

		&__control:not([type="checkbox"]):checked + &__decorator {
			background-image: radial-gradient(circle at center, $dark-green 60%, white 0);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 22px;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			vertical-align: middle;
			border: 1px solid $dark-green;
			-webkit-appearance: none;
			outline: none;
			cursor: pointer;
		}

		&__control:not([type="radio"]):checked + &__decorator {
			background-color: $dark-green !important;
			background-image: url("../../img/icons/images/check.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 15px 10px;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			vertical-align: middle;
			border: 1px solid $dark-green;
			-webkit-appearance: none;
			outline: none;
			cursor: pointer;
		}

		&__svg {
			display: none !important;
		}
	}

	&-radio {
		&--alowhover {
		}

		&__decorator {
		}

		&__svg {
		}

		&--checked {
		}
	}

	&-checkbox {
		&__decorator {
			margin-top: 15px;
			margin-bottom: 15px;
			margin-left: 0px;
			background-color: $light-gray-secondary;
			background-image: none;
			border-radius: 50%;
			vertical-align: middle;
			border: 1px solid $dark-green;
			-webkit-appearance: none;
			height: 30px;
			width: 30px;
			outline: none;
			cursor: pointer;
		}

		&__decorator:not([type="radio"]):checked {
			background-color: $dark-green !important;
			background-image: url("../../img/icons/images/check.png");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 15px 10px;
			height: 30px;
			width: 30px;
			border-radius: 50%;
			vertical-align: middle;
			border: 1px solid $dark-green;
			-webkit-appearance: none;
			outline: none;
			cursor: pointer;
		}
	}

	&-q {
		&-col {
			&-1 {
				margin-top: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid $gray-secondary;
			}
		}
	}

	&-visuallyhidden {
	}

	&-text {
		padding: 0px;
		height: 100%;
		width: 100%;
		height: auto !important;
        padding: 0 !important;
		border: none;
		border-bottom: solid 1px $light-gray;
		background-color: transparent !important;
		margin-bottom: 90px;
	}

	&-text:focus {
		height: 100%;
		border: none;
		background-color: transparent !important;
		border-bottom: solid 1px $light-gray;
	}

	&-completedpage {
		height: 100%;
		width: 100%;
		background-color: transparent !important;
		text-align: center;
		margin: 0px !important;

		p {
			height: fit-content;
			// margin-left: 174px;
			// margin-top: -45px;
			margin-top: 25px;
			margin-bottom: auto;
			display: block;
			font-style: normal;
			font-weight: normal;
			font-size: 46px;
			line-height: 22px;
			color: $text;
			font-family: Nunito Sans;
		}
	}

	&-dropdown {
		margin-top: 10px;
		width: 100%;
		border-radius: 5px;
		height: 50px !important;
		border: 1px solid $dark-gray !important;
		background-image: url("../../img/icons/images/dropdown.png") !important;
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0 !important;
		background-size: 16px 10px !important;
		font-size: 20px;
	}

	&-dropdown:hover {
		margin-top: 10px;
		width: 100%;
		border-radius: 5px;
		height: 50px !important;
		border: 1px solid $black;
		background-image: url("../../img/icons/images/dropdown.png");
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0;
		background-size: 16px 10px;
	}

	&-dropdown:active {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $light-gray;
	}

	&-dropdown:focus {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $light-gray-secondary;
		background-image: url("../../img/icons/images/dropdown.png");
		background-repeat: no-repeat, repeat;
		background-position: right 0.7em top 50%, 0 0;
		background-size: 16px 10px;
	}

	&-dropdown option {
		color: $black;
		font-weight: 600;
	}

	&-dropdown option:hover {
		color: $black;
		font-weight: 600;
		background-color: $black !important;
	}

	&_qbln {
		width: 260px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		// min-height: 150px;
	}

	&-boolean {
		margin-top: 25px !important;
		margin-bottom: 100px !important;
		display: flex !important;

		&__label {
			margin-top: 5px !important;
			font-family: Nunito Sans;
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			line-height: 30px;
			margin-right: 20px;
			margin-left: 20px;
		}

		&__switch {
			background: $white!important;
			width: 120px !important;
			height: 40px !important;
			padding: 5px !important;
			border: 1px solid $dark-gray;
			border-radius: 40px !important;
		}

		&__slider {
			background-color: $dark-green !important;
			width: 30px !important;
			height: 30px !important;
		}
	}

	&-image {
		margin-right: auto;
		margin-left: auto;
	}
}

.sv-root-modern .sv-question__erbox {
	display: none;
}

.sv-boolean--indeterminate .sv-boolean__slider {
	margin-left: calc(45% - 0.625em) !important;
}

.sv-boolean--checked .sv-boolean__slider {
	margin-left: calc(90% - 1.25em) !important;
}

.btn-hovering:hover {
	background-color: $light-gray-secondary !important;
}

.sv-selectbase__label:hover {
	color: $dark-green;
}

.sv-dropdown:hover {
	border: 1px solid $black;
	border-bottom: 1px solid $black !important;
}

.sv-btn.sv-action-bar-item, .sv-btn {
	font-family: Nunito Sans !important;
	font-size: 16px !important;
}

.sv-title {
	margin-top: 5px !important;
	margin-bottom: 0px !important;
	font-weight: 400;
    font-style: normal;
    font-stretch: unset;
    line-height: unset;
    letter-spacing: unset;
}

.sv-root-modern .sv-container-modern {
	padding-top: 40px;
}

.sv-row:not(:last-child) {
    padding-bottom: 0px !important;
}



