// Fonts
$default-font: "Nunito Sans", sans-serif;

// Main text color
$text: #303030;
$white: #fff;
$black: #000;
$lighter-gray: #f9f9f9;
$light-gray: #f3f3f3;
$gray: #e8e8e8;
$dark-gray: #b2b2b2;
$darker-gray: #889199;
$light-black: #505051;
$ultra-light-green: #ddeeec;
$light-green: #469f7b;
$green: #38ab84;
$dark-green: #059483;
$red: #f06c5d;
$red-error: #ff6756;
$gray-secondary: #d9dee3;
$light-gray-secondary: #f4f6f8;
$green-secondary: #0feca1;
$dark-green-secondary: #027d6f;
$violet: #8662b2;
$yellow: #f5c004;
$rose: #ca2c92;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);
$gutter: $grid-gutter-width;
